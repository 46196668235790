.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.loadingText {
  padding-left: 0.5rem;
}
